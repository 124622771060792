import * as React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTicket, faPeopleGroup } from '@fortawesome/free-solid-svg-icons'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'



const IndexPage = () => {
  return (
  <div class="container">
    <div class="intro_bg"></div>
    <div class="page_title">
      <h1>Derwish on the way for the Future</h1>
    </div>
    <svg version="1.1" class="page_title_svg" width="1138" height="197" viewBox="0 0 1138 197" xmlns="http://www.w3.org/2000/svg" fill="white">

<g>
	<g>
		<g>
			<g>
				<path class="st0" d="M79.5,89.7c0-23.8,0-47.6,0-71.3c0-0.6,0.2-1.1,0.7-1.6c0.4-0.5,0.9-0.8,1.5-0.8c9-0.9,13.5-1.4,22.5-2.2
					c3.3-0.3,6.3,0.1,9,1.1c2.8,1,5.2,2.6,7.2,4.6c2.1,2,3.8,4.4,5.1,7.2c1.3,2.8,2.1,5.8,2.5,9c0.5,4.5,0.8,9.1,0.8,13.9
					s-0.3,9.4-0.8,14c-0.4,3.2-1.2,6.4-2.5,9.4c-1.3,3-3,5.7-5.1,8c-2.1,2.4-4.5,4.3-7.2,5.8c-2.8,1.5-5.8,2.4-9,2.7
					c-9,0.8-13.5,1.2-22.5,2.2c-0.6,0.1-1.1-0.1-1.5-0.5C79.8,90.7,79.5,90.2,79.5,89.7z M94.5,74.1c0,0.7,0.4,1,1.1,1
					c3.5-0.3,5.2-0.5,8.7-0.8c2.5-0.2,4.6-1.3,6.1-3.1c1.6-1.9,2.5-4,2.8-6.4c0.5-4.6,0.8-9.3,0.8-14c0-4.7-0.3-9.4-0.8-13.9
					c-0.3-2.4-1.2-4.4-2.8-5.9c-1.6-1.6-3.6-2.3-6.1-2.1c-3.5,0.3-5.2,0.5-8.7,0.8c-0.7,0.1-1.1,0.5-1.1,1.2
					C94.5,45.3,94.5,59.7,94.5,74.1z"/>
				<path class="st0" d="M141.9,84.3c0-23.8,0-47.6,0-71.3c0-0.6,0.2-1.1,0.7-1.5c0.4-0.5,0.9-0.7,1.5-0.8
					c13.1-0.9,26.2-1.6,39.3-2.2c0.6,0,1.1,0.2,1.5,0.6c0.4,0.4,0.7,0.9,0.7,1.5c0,4.3,0,6.5,0,10.8c0,0.6-0.2,1.1-0.7,1.5
					c-0.4,0.5-0.9,0.7-1.5,0.7c-10.1,0.5-15.2,0.7-25.4,1.3c-0.7,0-1.1,0.4-1.1,1.2c0,5.2,0,7.8,0,12.9c0,0.7,0.4,1.1,1.1,1
					c9.8-0.6,14.7-0.8,24.5-1.3c0.6,0,1.1,0.2,1.5,0.6c0.4,0.4,0.7,0.9,0.7,1.5c0,4.3,0,6.5,0,10.8c0,0.6-0.2,1.1-0.6,1.5
					c-0.4,0.5-0.9,0.7-1.5,0.7c-9.8,0.4-14.7,0.7-24.5,1.3c-0.7,0-1.1,0.4-1.1,1.1c0,5.2,0,7.8,0,13c0,0.7,0.4,1,1.1,1
					c10.3-0.6,15.5-0.9,25.8-1.3c0.6,0,1.1,0.2,1.5,0.6c0.4,0.4,0.7,0.9,0.7,1.5c0,4.3,0,6.5,0,10.8c0,0.6-0.2,1.1-0.7,1.5
					c-0.4,0.5-0.9,0.7-1.5,0.7c-13.2,0.6-26.5,1.3-39.7,2.2c-0.6,0-1.1-0.1-1.5-0.6C142.1,85.4,141.9,84.9,141.9,84.3z"/>
				<path class="st0" d="M199.9,81.4c0-23.8,0-47.6,0-71.3c0-0.6,0.2-1.1,0.6-1.5c0.4-0.5,0.9-0.7,1.5-0.7
					c9.4-0.3,14.2-0.5,23.6-0.7c3.1-0.1,6,0.5,8.8,1.6c2.8,1.1,5.2,2.7,7.2,4.7c2,2,3.7,4.4,4.8,7.1c1.2,2.7,1.8,5.7,1.8,8.8
					c0,4.2-0.9,7.8-2.7,10.8c-1.8,3-4.6,5.9-8.5,8.5c-0.8,0.6-1.2,1.1-1.2,1.6c0,0.5,0.2,1.2,0.5,2c3.8,8.9,7.6,17.9,11.4,26.8
					c0.4,0.8,0.4,1.5,0.1,2.2c-0.3,0.7-1,1-2.1,1c-4.4,0.1-6.6,0.1-11,0.2c-0.9,0-1.5-0.2-2-0.6c-0.5-0.4-0.8-0.9-1.1-1.5
					c-3.7-8.8-7.4-17.6-11.1-26.4c-0.1-0.3-0.4-0.6-0.6-0.9c-0.3-0.3-0.7-0.5-1.3-0.4c-1.1,0-1.7,0-2.8,0.1c-0.7,0-1.1,0.4-1.1,1.1
					c0,10.8,0,16.2,0,27c0,0.6-0.2,1.1-0.7,1.5s-0.9,0.7-1.5,0.7c-4.3,0.1-6.4,0.2-10.7,0.4c-0.6,0-1-0.2-1.5-0.6
					C200.1,82.4,199.9,81.9,199.9,81.4z M214.8,36.5c0,0.7,0.4,1.1,1.1,1.1c3.9-0.1,5.8-0.2,9.7-0.3c2.1-0.1,3.9-0.8,5.4-2.3
					c1.5-1.5,2.2-3.3,2.2-5.4c0-2.1-0.7-3.8-2.2-5.3c-1.5-1.4-3.3-2.1-5.4-2.1c-3.9,0.1-5.8,0.1-9.7,0.3c-0.7,0-1.1,0.4-1.1,1.1
					C214.8,28.8,214.8,31.4,214.8,36.5z"/>
				<path class="st0" d="M253.6,8.7c0-0.1,0-0.2,0-0.3c0-0.5,0.1-0.9,0.4-1.3c0.3-0.4,0.9-0.6,1.7-0.7c4,0,6-0.1,10.1-0.1
					c0.5,0,0.9,0.1,1.3,0.4c0.4,0.3,0.6,0.7,0.7,1.2c1.4,14.4,2.8,28.8,4.2,43.2c0.1,0.5,0.3,0.7,0.7,0.8c0.4,0,0.7-0.2,0.8-0.8
					c1.6-8.6,2.3-13,3.9-21.6c0.2-1.1,0.9-1.6,2.1-1.6c2.6,0,3.9,0,6.5,0c1.2,0,1.8,0.5,2.1,1.6c1.6,8.6,2.4,13,4,21.6
					c0.1,0.5,0.4,0.7,0.8,0.8c0.4,0,0.6-0.2,0.7-0.8c1.4-14.4,2.7-28.8,4.1-43.2c0.1-0.6,0.3-1,0.7-1.2c0.4-0.3,0.8-0.4,1.4-0.4
					c4,0,6,0,10,0.1c0.9,0,1.5,0.2,1.8,0.6c0.2,0.3,0.4,0.8,0.4,1.2c0,0.1,0,0.2,0,0.3c-2.6,23.9-5.1,47.9-7.7,71.8
					c-0.1,0.6-0.3,1-0.7,1.2c-0.4,0.3-0.9,0.4-1.3,0.4c-5.2,0-7.8,0-13,0c-1.2,0-1.8-0.5-2.1-1.6c-1.5-7.8-2.3-11.7-3.8-19.5
					c-0.1-0.4-0.3-0.5-0.6-0.6c-0.4,0-0.6,0.2-0.6,0.6c-1.5,7.8-2.3,11.7-3.8,19.5c-0.2,1.1-0.9,1.6-2.1,1.6c-5.2,0-7.8,0-12.9,0.1
					c-0.5,0-0.9-0.1-1.3-0.4c-0.4-0.2-0.6-0.7-0.7-1.3C258.7,56.6,256.1,32.6,253.6,8.7z"/>
				<path class="st0" d="M319.3,80c0-4.1,0-6.2,0-10.3c0-0.6,0.2-1.1,0.7-1.5c0.4-0.4,1-0.6,1.5-0.6c4.8,0.1,7.2,0.1,12,0.2
					c0.7,0,1.1-0.4,1.1-1.1c0-14.8,0-29.6,0-44.4c0-0.7-0.4-1.1-1.1-1.1c-4.4-0.1-6.6-0.1-10.9-0.2c-0.6,0-1.1-0.2-1.5-0.7
					c-0.4-0.4-0.7-0.9-0.7-1.5c0-4.1,0-6.2,0-10.3c0-0.6,0.2-1.1,0.6-1.5c0.4-0.4,0.9-0.6,1.5-0.6c13,0.2,26.1,0.4,39.1,0.7
					c0.6,0,1.1,0.2,1.5,0.7c0.4,0.4,0.7,1,0.7,1.5c0,4.1,0,6.2,0,10.3c0,0.6-0.2,1.1-0.7,1.5c-0.4,0.4-1,0.6-1.5,0.6
					c-4.4-0.1-6.6-0.2-11-0.2c-0.7,0-1.1,0.3-1.1,1c0,14.8,0,29.6,0,44.4c0,0.7,0.4,1.1,1.1,1.1c4.8,0.1,7.2,0.2,12,0.3
					c0.6,0,1.1,0.2,1.5,0.7c0.4,0.4,0.7,0.9,0.7,1.5c0,4.1,0,6.2,0,10.3c0,0.6-0.2,1.1-0.6,1.5c-0.4,0.4-0.9,0.6-1.5,0.6
					c-13.7-0.3-27.5-0.6-41.2-0.8c-0.6,0-1.1-0.2-1.5-0.7C319.5,81.1,319.3,80.6,319.3,80z"/>
				<path class="st0" d="M375.6,60.8c0-1.3,0-1.9,0-3.2c0-0.6,0.2-1.1,0.6-1.5s0.9-0.6,1.5-0.6c4.3,0.1,6.4,0.2,10.7,0.3
					c0.6,0,1,0.2,1.5,0.7c0.4,0.4,0.6,1,0.6,1.6c0,1.3,0,1.9,0,3.2c0,2.5,0.9,4.7,2.7,6.4c1.8,1.7,4.4,2.7,7.7,2.8
					c3.3,0.1,5.9-0.6,7.9-2.3c2-1.6,3-3.7,3-6.2c0-2.3-0.9-4.1-2.6-5.5c-1.7-1.3-4.7-2.6-8.8-3.6c-3.4-0.8-6.6-1.9-9.5-3.2
					c-2.9-1.3-5.4-2.9-7.5-4.7c-2.1-1.9-3.8-4.1-5-6.7c-1.2-2.6-1.9-5.6-1.9-9.1c0-3.2,0.6-6.1,1.8-8.8c1.2-2.7,2.9-5.1,5.1-7.1
					c2.2-2,4.8-3.5,7.7-4.6c3-1.1,6.2-1.6,9.7-1.4c3.6,0.1,6.9,0.8,9.9,2.1c3.1,1.3,5.7,3,7.8,5.1c2.2,2.1,3.9,4.6,5.1,7.4
					c1.2,2.8,1.8,5.8,1.8,9c0,0.7,0,1,0,1.6c0,0.6-0.2,1.1-0.6,1.5c-0.4,0.4-0.9,0.6-1.5,0.6c-4.3-0.2-6.4-0.2-10.7-0.4
					c-0.6,0-1-0.3-1.5-0.7c-0.4-0.5-0.6-1-0.6-1.5c0-0.7,0-1,0-1.7c0-2.2-0.8-4.1-2.5-5.8c-1.7-1.7-4.1-2.6-7.2-2.7
					c-3.1-0.1-5.4,0.5-7,1.9c-1.6,1.4-2.5,3.2-2.5,5.4c0,2.1,0.9,3.8,2.7,5.1c1.8,1.3,4.2,2.3,7,3c3.7,0.9,7.2,2.1,10.3,3.3
					c3.1,1.3,5.8,2.9,8,4.9c2.2,2,4,4.4,5.3,7.2c1.3,2.8,1.9,6.1,1.9,9.8c0,3.5-0.6,6.7-1.9,9.5c-1.3,2.8-3,5.3-5.3,7.3
					c-2.3,2-5,3.5-8.2,4.6c-3.2,1-6.6,1.5-10.4,1.4c-3.7-0.1-7.1-0.9-10.2-2.2c-3.1-1.3-5.8-3.1-8-5.3c-2.2-2.2-4-4.8-5.3-7.7
					C376.2,67.2,375.6,64.1,375.6,60.8z"/>
				<path class="st0" d="M436.6,83.3c0-23.8,0-47.6,0-71.3c0-0.6,0.2-1.1,0.7-1.5c0.4-0.4,0.9-0.6,1.5-0.6c4.3,0.2,6.4,0.3,10.7,0.4
					c0.6,0,1,0.3,1.5,0.7c0.4,0.5,0.7,1,0.7,1.5c0,10.8,0,16.2,0,27c0,0.7,0.4,1.1,1.1,1.2c6.6,0.3,9.9,0.4,16.5,0.7
					c0.7,0,1.1-0.3,1.1-1.1c0-10.8,0-16.2,0-27c0-0.6,0.2-1.1,0.7-1.5c0.4-0.4,0.9-0.6,1.5-0.6c4.3,0.2,6.4,0.3,10.7,0.5
					c0.6,0,1,0.3,1.5,0.7c0.4,0.5,0.7,1,0.7,1.5c0,23.8,0,47.6,0,71.3c0,0.6-0.2,1.1-0.7,1.5c-0.4,0.4-0.9,0.6-1.5,0.6
					c-4.3-0.2-6.4-0.3-10.7-0.5c-0.6,0-1-0.3-1.5-0.7c-0.4-0.5-0.7-1-0.7-1.5c0-10.8,0-16.2,0-27c0-0.7-0.4-1.1-1.1-1.1
					c-6.6-0.3-9.9-0.4-16.5-0.7c-0.7,0-1.1,0.3-1.1,1c0,10.8,0,16.2,0,27c0,0.6-0.3,1.1-0.9,1.5c-0.6,0.4-1.2,0.6-1.7,0.6
					c-4.1-0.2-6.1-0.3-10.1-0.4c-0.6,0-1-0.3-1.5-0.7S436.6,83.8,436.6,83.3z"/>
			</g>
			<g>
				<path class="st0" d="M524.8,59.6c-0.2-2.6-0.2-5.3-0.2-7.9c0-2.7,0.1-5.3,0.2-7.8c0.2-2.5,0.4-5,0.7-7.3c0.4-3.2,1.2-6.2,2.5-9
					c1.3-2.8,3.1-5.3,5.2-7.3c2.2-2.1,4.7-3.7,7.7-4.8c3-1.1,6.3-1.6,10-1.4c3.7,0.2,7,1,10,2.4c3,1.4,5.5,3.3,7.7,5.6
					c2.2,2.3,3.9,4.9,5.2,7.9c1.3,3,2.1,6.1,2.5,9.3c0.3,2.3,0.5,4.8,0.6,7.3c0.1,2.6,0.2,5.2,0.2,7.9c0,2.7-0.1,5.3-0.2,7.9
					c-0.1,2.6-0.3,5-0.6,7.2c-0.4,3.2-1.2,6.2-2.5,9c-1.3,2.8-3.1,5.3-5.2,7.3c-2.2,2.1-4.7,3.7-7.7,4.8c-3,1.1-6.3,1.6-10,1.4
					c-3.7-0.2-7-1-10-2.4c-3-1.4-5.5-3.3-7.7-5.6c-2.2-2.3-3.9-4.9-5.2-7.9c-1.3-3-2.1-6.1-2.5-9.3
					C525.1,64.7,524.9,62.2,524.8,59.6z M539.7,44.6c-0.2,2.6-0.2,5.2-0.2,7.9c0,2.7,0.1,5.3,0.2,7.9c0.1,2.6,0.4,5.1,0.7,7.3
					c0.3,2.4,1.3,4.5,3,6.3c1.7,1.8,4.2,2.8,7.5,2.9c3.3,0.2,5.8-0.5,7.5-2.2c1.7-1.6,2.7-3.6,3-6c0.3-2.2,0.5-4.6,0.6-7.2
					c0.1-2.6,0.2-5.3,0.1-7.9c0-2.7-0.1-5.3-0.2-7.9c-0.1-2.6-0.3-5-0.6-7.4c-0.3-2.4-1.3-4.5-3-6.3c-1.7-1.8-4.2-2.7-7.5-2.9
					c-3.3-0.2-5.8,0.5-7.5,2.2c-1.7,1.6-2.7,3.6-3,5.9C540.1,39.6,539.9,42,539.7,44.6z"/>
				<path class="st0" d="M586.2,90.6c0-23.8,0-47.6,0-71.3c0-0.6,0.2-1.1,0.7-1.5c0.4-0.4,0.9-0.6,1.5-0.6c5.1,0.3,7.6,0.4,12.7,0.7
					c0.8,0,1.4,0.3,1.8,0.7c0.4,0.4,0.7,0.9,0.8,1.4c5,14,9.9,27.9,14.9,41.9c0.1,0.4,0.3,0.5,0.6,0.5c0.2-0.1,0.4-0.3,0.4-0.7
					c0-13.5,0-27.1,0-40.6c0-0.6,0.2-1.1,0.7-1.5c0.4-0.4,0.9-0.6,1.5-0.6c4.3,0.2,6.4,0.3,10.7,0.5c0.6,0,1,0.3,1.5,0.7
					c0.4,0.5,0.7,1,0.7,1.5c0,23.8,0,47.6,0,71.3c0,0.6-0.2,1.1-0.7,1.5s-0.9,0.6-1.5,0.6c-5-0.3-7.6-0.4-12.6-0.6
					c-0.9,0-1.6-0.3-2-0.7c-0.4-0.4-0.7-0.9-0.8-1.3c-4.9-12.9-9.8-25.8-14.7-38.7c-0.1-0.4-0.3-0.5-0.6-0.5
					c-0.3,0.1-0.4,0.3-0.4,0.6c0,12.5,0,24.9,0,37.4c0,0.6-0.2,1.1-0.6,1.5c-0.4,0.4-0.9,0.6-1.5,0.6c-4.3-0.2-6.4-0.3-10.7-0.6
					c-0.6,0-1-0.3-1.5-0.7C586.4,91.6,586.2,91.1,586.2,90.6z"/>
			</g>
			<g>
				<path class="st0" d="M674.7,34.4c0-4.3,0-6.5,0-10.8c0-0.6,0.2-1.1,0.6-1.5c0.4-0.4,0.9-0.6,1.5-0.6c15.5,0.7,31.1,1.3,46.6,1.9
					c0.6,0,1.1,0.3,1.5,0.7c0.4,0.5,0.7,1,0.7,1.5c0,4.3,0,6.5,0,10.8c0,0.6-0.2,1.1-0.6,1.5c-0.4,0.4-0.9,0.6-1.5,0.6
					c-5.9-0.2-8.8-0.3-14.7-0.6c-0.7,0-1.1,0.3-1.1,1.1c0,19.1,0,38.2,0,57.3c0,0.6-0.2,1.1-0.7,1.5c-0.4,0.4-0.9,0.6-1.5,0.6
					c-4.3-0.2-6.4-0.3-10.7-0.4c-0.6,0-1-0.3-1.5-0.7s-0.7-1-0.7-1.5c0-19.1,0-38.2,0-57.3c0-0.7-0.4-1.1-1.1-1.2
					c-5.9-0.2-8.8-0.4-14.7-0.6c-0.6,0-1.1-0.3-1.5-0.7C674.9,35.5,674.7,35,674.7,34.4z"/>
				<path class="st0" d="M735.4,97.3c0-23.8,0-47.6,0-71.3c0-0.6,0.2-1.1,0.7-1.5c0.4-0.4,0.9-0.6,1.5-0.6c4.3,0.1,6.4,0.2,10.7,0.3
					c0.6,0,1,0.3,1.5,0.7c0.4,0.5,0.7,1,0.7,1.5c0,10.8,0,16.2,0,27c0,0.7,0.4,1.1,1.1,1.1c6.6,0.2,9.9,0.3,16.5,0.5
					c0.7,0,1.1-0.3,1.1-1.1c0-10.8,0-16.2,0-27c0-0.6,0.2-1.1,0.7-1.5c0.4-0.4,0.9-0.6,1.5-0.6c4.3,0.1,6.4,0.2,10.7,0.3
					c0.6,0,1,0.2,1.5,0.7c0.4,0.4,0.7,1,0.7,1.5c0,23.8,0,47.6,0,71.3c0,0.6-0.2,1.1-0.7,1.5c-0.4,0.4-0.9,0.6-1.5,0.6
					c-4.3-0.1-6.4-0.1-10.7-0.3c-0.6,0-1-0.2-1.5-0.7c-0.4-0.5-0.7-1-0.7-1.5c0-10.8,0-16.2,0-27c0-0.7-0.4-1.1-1.1-1.1
					c-6.6-0.2-9.9-0.3-16.5-0.5c-0.7,0-1.1,0.3-1.1,1c0,10.8,0,16.2,0,27c0,0.6-0.3,1.1-0.9,1.5s-1.2,0.6-1.7,0.6
					c-4.1-0.1-6.1-0.2-10.1-0.3c-0.6,0-1-0.3-1.5-0.7C735.6,98.4,735.4,97.9,735.4,97.3z"/>
				<path class="st0" d="M797.1,99c0-23.8,0-47.6,0-71.3c0-0.6,0.2-1.1,0.7-1.5c0.4-0.4,0.9-0.6,1.5-0.6c13.1,0.2,26.2,0.4,39.3,0.4
					c0.6,0,1.1,0.2,1.5,0.7c0.4,0.4,0.7,0.9,0.7,1.5c0,4.3,0,6.5,0,10.8c0,0.6-0.2,1.1-0.7,1.5c-0.4,0.4-0.9,0.7-1.5,0.7
					c-10.1,0-15.2-0.1-25.4-0.2c-0.7,0-1.1,0.4-1.1,1.1c0,5.2,0,7.8,0,12.9c0,0.7,0.4,1.1,1.1,1.1c9.8,0.1,14.7,0.2,24.5,0.2
					c0.6,0,1.1,0.2,1.5,0.6c0.4,0.4,0.7,0.9,0.7,1.5c0,4.3,0,6.5,0,10.8c0,0.6-0.2,1.1-0.6,1.5c-0.4,0.4-0.9,0.7-1.5,0.7
					c-9.8,0-14.7-0.1-24.5-0.2c-0.7,0-1.1,0.3-1.1,1c0,5.2,0,7.8,0,13c0,0.7,0.4,1.1,1.1,1.1c10.3,0.1,15.5,0.2,25.8,0.2
					c0.6,0,1.1,0.2,1.5,0.7c0.4,0.4,0.7,0.9,0.7,1.5c0,4.3,0,6.5,0,10.8c0,0.6-0.2,1.1-0.7,1.5c-0.4,0.4-0.9,0.7-1.5,0.7
					c-13.2-0.1-26.5-0.2-39.7-0.4c-0.6,0-1.1-0.2-1.5-0.7C797.4,100.1,797.1,99.5,797.1,99z"/>
			</g>
			<g>
				<path class="st0" d="M883.5,28c0-0.1,0-0.2,0-0.3c0-0.5,0.1-0.9,0.4-1.3c0.3-0.4,0.9-0.6,1.7-0.7c4-0.1,6-0.1,10.1-0.2
					c0.5,0,0.9,0.1,1.3,0.4c0.4,0.3,0.6,0.7,0.7,1.2c1.4,14.4,2.8,28.8,4.2,43.1c0.1,0.5,0.3,0.7,0.7,0.8c0.4,0,0.7-0.3,0.8-0.8
					c1.6-8.7,2.3-13,3.9-21.7c0.2-1.1,0.9-1.7,2.1-1.7c2.6-0.1,3.9-0.1,6.5-0.2c1.2,0,1.8,0.5,2.1,1.6c1.6,8.6,2.4,12.9,4,21.5
					c0.1,0.5,0.4,0.7,0.8,0.7c0.4,0,0.6-0.3,0.7-0.8c1.4-14.4,2.7-28.9,4.1-43.3c0.1-0.6,0.3-1,0.7-1.3c0.4-0.3,0.8-0.4,1.4-0.4
					c4-0.1,6-0.2,10-0.4c0.9,0,1.5,0.2,1.8,0.6c0.2,0.3,0.4,0.7,0.4,1.2c0,0.1,0,0.2,0,0.3c-2.6,24.1-5.1,48.1-7.7,72.2
					c-0.1,0.6-0.3,1-0.7,1.3c-0.4,0.3-0.9,0.4-1.3,0.4c-5.2,0.2-7.8,0.3-13,0.4c-1.2,0-1.8-0.5-2.1-1.6c-1.5-7.8-2.3-11.7-3.8-19.4
					c-0.1-0.3-0.3-0.5-0.6-0.5c-0.4,0-0.6,0.2-0.6,0.6c-1.5,7.8-2.3,11.8-3.8,19.6c-0.2,1.1-0.9,1.7-2.1,1.7
					c-5.2,0.1-7.8,0.2-12.9,0.2c-0.5,0-0.9-0.1-1.3-0.3c-0.4-0.2-0.6-0.7-0.7-1.3C888.6,75.8,886,51.9,883.5,28z"/>
				<path class="st0" d="M943.4,97.8c6.5-24.2,12.9-48.5,19.4-72.7c0.2-1.1,0.9-1.7,2.1-1.7c5.8-0.3,8.7-0.4,14.5-0.8
					c1.2-0.1,1.8,0.4,2.1,1.5c6.4,23.5,12.8,47.1,19.3,70.6c0,0.1,0,0.3,0.1,0.4c0,0.3-0.1,0.7-0.4,1c-0.4,0.5-1,0.8-1.8,0.9
					c-4.5,0.3-6.8,0.5-11.3,0.7c-1,0.1-1.7-0.4-2.1-1.5c-1.4-4.4-2-6.6-3.4-10.9c-0.1-0.4-0.3-0.8-0.5-1.1c-0.2-0.3-0.6-0.4-1.1-0.4
					c-6.6,0.4-9.9,0.6-16.5,0.9c-0.9,0-1.4,0.6-1.6,1.7c-1.2,4.5-1.8,6.8-3.1,11.3c-0.2,1.1-0.9,1.7-2.1,1.7
					c-4.5,0.2-6.8,0.3-11.3,0.5c-0.9,0-1.5-0.2-1.8-0.7c-0.2-0.3-0.4-0.7-0.4-1C943.4,98.1,943.4,97.9,943.4,97.8z M966.2,68.2
					c0,0.2-0.1,0.3-0.1,0.5c0,0.5,0.3,0.7,0.9,0.7c4-0.2,6-0.3,9.9-0.5c0.6,0,1-0.3,1-0.8c0-0.1,0-0.3-0.1-0.5
					c-2.1-10.2-3.1-15.4-5.2-25.6c-0.1-0.3-0.2-0.4-0.5-0.4c-0.1,0-0.1,0-0.2,0c-0.3,0-0.5,0.2-0.5,0.5
					C969.3,52.5,968.2,57.7,966.2,68.2z"/>
				<path class="st0" d="M1004.6,23.9c-0.2-0.4-0.3-0.7-0.3-1c0-0.4,0.1-0.7,0.3-1.1c0.4-0.7,1-1,1.7-1.1c4.8-0.3,7.2-0.5,11.9-0.9
					c1-0.1,1.7,0.4,2.1,1.5c3.5,9.7,7,19.4,10.4,29c0.1,0.3,0.2,0.4,0.5,0.4c0.1,0,0.1,0,0.2,0c0.3,0,0.5-0.2,0.5-0.5
					c3.5-10.3,7-20.6,10.4-30.9c0.4-1.1,1-1.7,2.1-1.8c4.8-0.5,7.2-0.7,11.9-1.2c0.7-0.1,1.3,0.2,1.7,0.8c0.2,0.3,0.3,0.6,0.3,1
					c0,0.3-0.1,0.7-0.3,1.1c-6.4,16.7-12.9,33.4-19.3,50.1c0,8.9,0,13.4,0,22.3c0,0.6-0.2,1.1-0.7,1.6s-0.9,0.7-1.5,0.8
					c-4.3,0.4-6.4,0.6-10.7,0.9c-0.6,0-1-0.1-1.5-0.5s-0.7-0.9-0.7-1.4c0-8.9,0-13.4,0-22.3C1017.5,55.1,1011,39.5,1004.6,23.9z"/>
			</g>
		</g>
		<g>
			<path class="st0" d="M194.8,173.8c0-23.6,0-47.3,0-70.9c0-0.6,0.2-1.1,0.6-1.5c0.4-0.5,0.9-0.7,1.5-0.7
				c12.5-0.5,25.1-0.8,37.6-1.1c0.6,0,1.1,0.2,1.5,0.6c0.4,0.4,0.7,0.9,0.7,1.5c0,4.3,0,6.5,0,10.8c0,0.6-0.2,1.1-0.7,1.5
				c-0.4,0.4-0.9,0.7-1.5,0.7c-9.5,0.2-14.3,0.3-23.8,0.6c-0.7,0-1.1,0.4-1.1,1.1c0,5.1,0,7.7,0,12.8c0,0.7,0.4,1.1,1.1,1.1
				c9.1-0.3,13.6-0.4,22.7-0.6c0.6,0,1.1,0.2,1.5,0.6c0.4,0.4,0.7,0.9,0.7,1.5c0,4.3,0,6.5,0,10.8c0,0.6-0.2,1.1-0.7,1.5
				c-0.4,0.4-0.9,0.7-1.5,0.7c-9.1,0.2-13.6,0.3-22.7,0.6c-0.7,0-1.1,0.4-1.1,1.1c0,10.7,0,16.1,0,26.9c0,0.6-0.2,1.1-0.6,1.5
				c-0.4,0.5-0.9,0.7-1.5,0.7c-4.2,0.1-6.4,0.2-10.6,0.4c-0.6,0-1-0.2-1.5-0.6C195,174.9,194.8,174.4,194.8,173.8z"/>
			<path class="st0" d="M246.5,144.8c-0.2-2.6-0.2-5.2-0.2-7.9c0-2.7,0.1-5.3,0.2-7.8c0.2-2.5,0.4-5,0.6-7.3
				c0.4-3.2,1.2-6.3,2.5-9.2c1.3-2.9,3.1-5.4,5.2-7.6c2.2-2.2,4.7-3.9,7.7-5.2c2.9-1.3,6.2-1.9,9.9-2c3.7,0,7,0.6,9.9,1.9
				c3,1.3,5.5,3,7.7,5.1c2.2,2.2,3.9,4.7,5.2,7.6c1.3,2.9,2.1,5.9,2.5,9.1c0.3,2.3,0.5,4.7,0.6,7.3c0.1,2.5,0.2,5.1,0.2,7.8
				c0,2.7-0.1,5.3-0.2,7.9c-0.1,2.6-0.3,5-0.6,7.2c-0.4,3.2-1.2,6.3-2.5,9.1c-1.3,2.9-3.1,5.4-5.2,7.6c-2.2,2.2-4.7,3.9-7.6,5.2
				c-2.9,1.3-6.3,1.9-10,1.9c-3.7,0-7-0.6-9.9-1.8c-2.9-1.2-5.5-2.9-7.7-5.1c-2.2-2.2-3.9-4.7-5.2-7.5c-1.3-2.8-2.1-5.9-2.5-9.1
				C246.8,149.8,246.6,147.4,246.5,144.8z M261.3,128.9c-0.2,2.5-0.2,5.2-0.2,7.8c0,2.7,0.1,5.3,0.2,7.9c0.1,2.6,0.4,5,0.7,7.2
				c0.3,2.4,1.3,4.4,2.9,6.1c1.7,1.7,4.2,2.5,7.5,2.5c3.3,0,5.8-0.9,7.5-2.6c1.7-1.7,2.7-3.7,3-6.1c0.3-2.2,0.5-4.6,0.6-7.2
				c0.1-2.6,0.2-5.2,0.1-7.9c0-2.7-0.1-5.3-0.2-7.8c-0.1-2.5-0.3-5-0.6-7.3c-0.3-2.4-1.3-4.4-3-6.1c-1.7-1.7-4.2-2.5-7.5-2.5
				c-3.3,0-5.8,0.9-7.5,2.6c-1.7,1.7-2.7,3.7-2.9,6.1C261.7,124,261.5,126.4,261.3,128.9z"/>
			<path class="st0" d="M308.4,172.2c0-23.6,0-47.3,0-70.9c0-0.6,0.2-1.1,0.6-1.5c0.4-0.4,0.9-0.6,1.5-0.6
				c9.4,0.1,14.1,0.1,23.4,0.3c3.1,0,6,0.7,8.7,1.9c2.7,1.2,5.1,2.9,7.2,5c2,2.1,3.6,4.5,4.8,7.3c1.2,2.8,1.7,5.7,1.7,8.8
				c0,4.2-0.9,7.7-2.6,10.6c-1.8,2.9-4.6,5.7-8.4,8.2c-0.8,0.5-1.2,1.1-1.2,1.6c0,0.5,0.2,1.2,0.5,2c3.8,9,7.6,18.1,11.4,27.1
				c0.3,0.8,0.4,1.5,0.1,2.2c-0.3,0.7-1,1-2.1,1c-4.4-0.1-6.6-0.1-11-0.2c-0.9,0-1.5-0.2-2-0.6c-0.5-0.4-0.8-0.9-1.1-1.6
				c-3.7-8.9-7.3-17.8-11-26.7c-0.1-0.3-0.4-0.6-0.6-0.9c-0.3-0.3-0.7-0.5-1.3-0.5c-1.1,0-1.7,0-2.8,0c-0.7,0-1.1,0.3-1.1,1
				c0,10.7,0,16.1,0,26.9c0,0.6-0.2,1.1-0.6,1.5c-0.4,0.4-0.9,0.6-1.5,0.6c-4.2-0.1-6.4-0.1-10.6-0.1c-0.6,0-1-0.2-1.5-0.7
				C308.6,173.2,308.4,172.7,308.4,172.2z M323.3,128.3c0,0.7,0.4,1.1,1.1,1.1c3.9,0.1,5.8,0.1,9.7,0.1c2.1,0,3.8-0.7,5.3-2.1
				c1.5-1.4,2.2-3.2,2.2-5.3c0-2.1-0.7-3.8-2.2-5.3c-1.5-1.5-3.3-2.3-5.4-2.3c-3.9-0.1-5.8-0.1-9.7-0.1c-0.7,0-1.1,0.4-1.1,1.1
				C323.3,120.6,323.3,123.1,323.3,128.3z"/>
			<g>
				<path class="st0" d="M395.4,113.9c0-4.3,0-6.5,0-10.8c0-0.6,0.2-1.1,0.6-1.5c0.4-0.4,0.9-0.6,1.5-0.6
					c15.5,0.5,30.9,1.1,46.3,1.7c0.6,0,1.1,0.3,1.5,0.7c0.4,0.5,0.7,1,0.7,1.5c0,4.3,0,6.5,0,10.8c0,0.6-0.2,1-0.6,1.5
					c-0.4,0.4-0.9,0.6-1.5,0.6c-5.9-0.2-8.8-0.4-14.6-0.6c-0.7,0-1.1,0.3-1.1,1.1c0,19,0,37.9,0,56.9c0,0.6-0.2,1-0.6,1.5
					c-0.4,0.4-0.9,0.6-1.5,0.6c-4.2-0.2-6.4-0.2-10.6-0.4c-0.6,0-1-0.3-1.5-0.7c-0.4-0.5-0.6-1-0.6-1.5c0-19,0-37.9,0-56.9
					c0-0.7-0.4-1.1-1.1-1.1c-5.9-0.2-8.8-0.3-14.6-0.5c-0.6,0-1.1-0.2-1.5-0.7C395.6,115,395.4,114.5,395.4,113.9z"/>
				<path class="st0" d="M455.7,176.3c0-23.6,0-47.3,0-70.9c0-0.6,0.2-1,0.6-1.5c0.4-0.4,0.9-0.6,1.5-0.6c4.2,0.2,6.4,0.3,10.6,0.5
					c0.6,0,1,0.3,1.5,0.7c0.4,0.5,0.6,1,0.6,1.5c0,10.7,0,16.1,0,26.9c0,0.7,0.4,1.1,1.1,1.2c6.5,0.3,9.8,0.5,16.4,0.8
					c0.7,0,1.1-0.3,1.1-1.1c0-10.7,0-16.1,0-26.9c0-0.6,0.2-1,0.6-1.5c0.4-0.4,0.9-0.6,1.5-0.6c4.2,0.2,6.4,0.3,10.6,0.5
					c0.6,0,1,0.3,1.5,0.7c0.4,0.5,0.6,1,0.6,1.5c0,23.6,0,47.3,0,70.9c0,0.6-0.2,1-0.6,1.5c-0.4,0.4-0.9,0.6-1.5,0.6
					c-4.2-0.2-6.4-0.3-10.6-0.5c-0.6,0-1-0.3-1.5-0.7c-0.4-0.5-0.6-1-0.6-1.5c0-10.7,0-16.1,0-26.9c0-0.7-0.4-1.1-1.1-1.1
					c-6.5-0.3-9.8-0.5-16.4-0.8c-0.7,0-1.1,0.3-1.1,1c0,10.7,0,16.1,0,26.9c0,0.6-0.3,1-0.9,1.5c-0.6,0.4-1.2,0.6-1.7,0.6
					c-4-0.2-6.1-0.3-10.1-0.5c-0.6,0-1-0.3-1.5-0.7C455.9,177.4,455.7,176.9,455.7,176.3z"/>
				<path class="st0" d="M517.1,179.3c0-23.6,0-47.3,0-70.9c0-0.6,0.2-1,0.7-1.5c0.4-0.4,0.9-0.6,1.5-0.6c13,0.7,26,1.3,39,2
					c0.6,0,1.1,0.3,1.5,0.7c0.4,0.5,0.7,1,0.7,1.5c0,4.3,0,6.5,0,10.8c0,0.6-0.2,1-0.7,1.5c-0.4,0.4-0.9,0.6-1.5,0.6
					c-10.1-0.5-15.1-0.8-25.2-1.3c-0.7,0-1.1,0.3-1.1,1c0,5.1,0,7.7,0,12.8c0,0.7,0.4,1.1,1.1,1.2c9.7,0.5,14.6,0.8,24.3,1.3
					c0.6,0,1.1,0.3,1.5,0.7c0.4,0.4,0.7,1,0.7,1.6c0,4.3,0,6.5,0,10.8c0,0.6-0.2,1-0.6,1.5c-0.4,0.4-0.9,0.6-1.5,0.6
					c-9.7-0.5-14.6-0.8-24.3-1.3c-0.7,0-1.1,0.3-1.1,1c0,5.2,0,7.7,0,12.9c0,0.7,0.4,1.1,1.1,1.1c10.2,0.5,15.4,0.8,25.6,1.3
					c0.6,0,1.1,0.3,1.5,0.7c0.4,0.5,0.7,1,0.7,1.5c0,4.3,0,6.5,0,10.8c0,0.6-0.2,1-0.7,1.5c-0.4,0.4-0.9,0.6-1.5,0.6
					c-13.1-0.7-26.3-1.4-39.4-2c-0.6,0-1.1-0.3-1.5-0.7C517.4,180.3,517.1,179.8,517.1,179.3z"/>
			</g>
			<g>
				<path class="st0" d="M607.3,183.9c0-23.6,0-47.3,0-70.9c0-0.6,0.2-1,0.6-1.5c0.4-0.4,0.9-0.6,1.5-0.6
					c12.5,0.6,25.1,1.3,37.6,1.9c0.6,0,1.1,0.3,1.5,0.7c0.4,0.5,0.7,1,0.7,1.5c0,4.3,0,6.5,0,10.8c0,0.6-0.2,1-0.7,1.5
					c-0.4,0.4-0.9,0.6-1.5,0.6c-9.5-0.5-14.3-0.7-23.8-1.2c-0.7,0-1.1,0.3-1.1,1c0,5.1,0,7.7,0,12.8c0,0.7,0.4,1.1,1.1,1.2
					c9.1,0.5,13.6,0.7,22.7,1.1c0.6,0,1.1,0.3,1.5,0.7s0.7,1,0.7,1.6c0,4.3,0,6.5,0,10.8c0,0.6-0.2,1-0.7,1.5
					c-0.4,0.4-0.9,0.6-1.5,0.6c-9.1-0.4-13.6-0.7-22.7-1.1c-0.7,0-1.1,0.3-1.1,1c0,10.7,0,16.1,0,26.9c0,0.6-0.2,1-0.6,1.5
					c-0.4,0.4-0.9,0.6-1.5,0.6c-4.2-0.2-6.4-0.3-10.6-0.5c-0.6,0-1-0.3-1.5-0.7C607.5,185,607.3,184.5,607.3,183.9z"/>
				<path class="st0" d="M659.6,166.1c0-16.8,0-33.7,0-50.5c0-0.6,0.2-1,0.6-1.5c0.4-0.4,0.9-0.6,1.5-0.6c4.3,0.2,6.4,0.3,10.6,0.5
					c0.5,0,1,0.3,1.5,0.7c0.4,0.5,0.6,1,0.6,1.5c0,16.9,0,33.7,0,50.6c0,2.4,0.9,4.5,2.7,6.2c1.8,1.8,4.3,2.7,7.5,2.9
					c3.3,0.1,5.9-0.6,7.7-2.2s2.8-3.6,2.8-6c0-16.9,0-33.7,0-50.6c0-0.6,0.2-1.1,0.6-1.5c0.4-0.4,0.9-0.6,1.5-0.6
					c4.3,0.2,6.4,0.3,10.6,0.4c0.5,0,1,0.3,1.5,0.7c0.4,0.5,0.6,1,0.6,1.5c0,16.8,0,33.7,0,50.5c0,3.2-0.6,6.3-1.9,9.1
					c-1.3,2.9-3,5.3-5.3,7.4c-2.2,2.1-4.9,3.7-8,4.8c-3.1,1.1-6.5,1.6-10.2,1.5c-3.6-0.2-6.9-0.9-10-2.3c-3.1-1.4-5.8-3.2-8-5.5
					c-2.2-2.3-4-4.9-5.2-7.8C660.2,172.4,659.6,169.3,659.6,166.1z"/>
				<path class="st0" d="M718.7,128.9c0-4.3,0-6.5,0-10.8c0-0.6,0.2-1.1,0.6-1.5c0.4-0.4,0.9-0.6,1.5-0.6
					c15.4,0.6,30.9,1.1,46.3,1.5c0.6,0,1.1,0.2,1.5,0.7c0.4,0.4,0.7,1,0.7,1.5c0,4.3,0,6.5,0,10.8c0,0.6-0.2,1.1-0.6,1.5
					c-0.4,0.4-0.9,0.6-1.5,0.6c-5.9-0.2-8.8-0.2-14.6-0.4c-0.7,0-1.1,0.3-1.1,1.1c0,19,0,37.9,0,56.9c0,0.6-0.2,1.1-0.6,1.5
					c-0.4,0.4-0.9,0.6-1.5,0.6c-4.2-0.1-6.4-0.2-10.6-0.3c-0.6,0-1-0.3-1.5-0.7c-0.4-0.5-0.6-1-0.6-1.5c0-19,0-37.9,0-56.9
					c0-0.7-0.4-1.1-1.1-1.1c-5.9-0.2-8.8-0.3-14.6-0.5c-0.6,0-1.1-0.2-1.5-0.7C718.9,130,718.7,129.5,718.7,128.9z"/>
				<path class="st0" d="M777.7,170.4c0-16.8,0-33.7,0-50.5c0-0.6,0.2-1.1,0.6-1.5c0.4-0.4,0.9-0.6,1.5-0.6
					c4.3,0.1,6.4,0.1,10.6,0.2c0.5,0,1,0.2,1.5,0.7c0.4,0.4,0.6,0.9,0.6,1.5c0,16.9,0,33.7,0,50.6c0,2.4,0.9,4.4,2.7,6.1
					c1.8,1.7,4.3,2.6,7.5,2.7c3.3,0.1,5.9-0.7,7.7-2.4c1.8-1.7,2.8-3.7,2.8-6.1c0-16.9,0-33.7,0-50.6c0-0.6,0.2-1.1,0.6-1.5
					c0.4-0.4,0.9-0.6,1.5-0.6c4.3,0.1,6.4,0.1,10.6,0.1c0.5,0,1,0.2,1.5,0.7c0.4,0.4,0.6,0.9,0.6,1.5c0,16.8,0,33.7,0,50.5
					c0,3.2-0.6,6.3-1.9,9.2c-1.3,2.9-3,5.4-5.3,7.5c-2.2,2.1-4.9,3.8-8,5c-3.1,1.2-6.5,1.8-10.2,1.7c-3.6-0.1-6.9-0.7-10-2.1
					c-3.1-1.3-5.8-3.1-8-5.3c-2.2-2.2-4-4.8-5.2-7.7C778.3,176.7,777.7,173.7,777.7,170.4z"/>
				<path class="st0" d="M839,191.7c0-23.6,0-47.3,0-70.9c0-0.6,0.2-1.1,0.6-1.5c0.4-0.4,0.9-0.7,1.5-0.6c9.4,0,14.1,0,23.4,0
					c3.1,0,6,0.6,8.7,1.7c2.7,1.2,5.1,2.8,7.2,4.8s3.6,4.4,4.8,7.1c1.2,2.7,1.7,5.6,1.7,8.7c0,4.2-0.9,7.7-2.6,10.7
					c-1.8,3-4.6,5.8-8.4,8.4c-0.8,0.6-1.2,1.1-1.2,1.6c0,0.5,0.2,1.2,0.5,2c3.8,8.9,7.6,17.8,11.4,26.7c0.3,0.8,0.4,1.5,0.1,2.2
					c-0.3,0.7-1,1-2.1,1c-4.4,0.1-6.6,0.1-11,0.1c-0.9,0-1.5-0.2-2-0.6c-0.5-0.4-0.8-0.9-1.1-1.5c-3.7-8.8-7.3-17.7-11-26.5
					c-0.1-0.3-0.4-0.6-0.6-0.9c-0.3-0.3-0.7-0.5-1.3-0.5c-1.1,0-1.7,0-2.8,0c-0.7,0-1.1,0.4-1.1,1.1c0,10.7,0,16.1,0,26.9
					c0,0.6-0.2,1.1-0.6,1.5c-0.4,0.4-0.9,0.7-1.5,0.7c-4.2,0-6.4,0-10.6,0c-0.6,0-1-0.2-1.5-0.7C839.2,192.8,839,192.3,839,191.7z
					 M853.9,147.7c0,0.7,0.4,1.1,1.1,1.1c3.9,0,5.8,0,9.7,0c2.1,0,3.8-0.8,5.3-2.2c1.5-1.5,2.2-3.3,2.2-5.4c0-2.1-0.7-3.8-2.2-5.3
					c-1.5-1.5-3.3-2.2-5.4-2.2c-3.9,0-5.8,0-9.7,0c-0.7,0-1.1,0.4-1.1,1.1C853.9,140,853.9,142.6,853.9,147.7z"/>
				<path class="st0" d="M899.4,191.3c0-23.6,0-47.3,0-70.9c0-0.6,0.2-1.1,0.7-1.5c0.4-0.4,0.9-0.7,1.5-0.7c13-0.3,26-0.6,39-1.1
					c0.6,0,1.1,0.2,1.5,0.6c0.4,0.4,0.7,0.9,0.7,1.5c0,4.3,0,6.5,0,10.8c0,0.6-0.2,1.1-0.7,1.5c-0.4,0.5-0.9,0.7-1.5,0.7
					c-10.1,0.4-15.1,0.5-25.2,0.8c-0.7,0-1.1,0.4-1.1,1.1c0,5.1,0,7.7,0,12.8c0,0.7,0.4,1.1,1.1,1.1c9.7-0.3,14.6-0.4,24.3-0.8
					c0.6,0,1.1,0.2,1.5,0.6c0.4,0.4,0.7,0.9,0.7,1.5c0,4.3,0,6.5,0,10.8c0,0.6-0.2,1.1-0.6,1.5c-0.4,0.5-0.9,0.7-1.5,0.7
					c-9.7,0.4-14.6,0.5-24.3,0.8c-0.7,0-1.1,0.4-1.1,1.1c0,5.2,0,7.7,0,12.9c0,0.7,0.4,1,1.1,1c10.2-0.3,15.4-0.4,25.6-0.8
					c0.6,0,1.1,0.2,1.5,0.6c0.4,0.4,0.7,0.9,0.7,1.5c0,4.3,0,6.5,0,10.8c0,0.6-0.2,1.1-0.7,1.5c-0.4,0.5-0.9,0.7-1.5,0.7
					c-13.1,0.5-26.3,0.9-39.4,1.1c-0.6,0-1.1-0.2-1.5-0.6C899.6,192.4,899.4,191.9,899.4,191.3z"/>
			</g>
		</g>
	</g>
</g>
</svg>

      <video class="tiny_walk" autoPlay loop muted playsInline>
          <source src="/movie-hevc.mov" type='video/mp4; codecs="hvc1"' />
          <source src="/movie-webm.webm" type="video/webm" />
        </video>
      <div class="news">
      </div>
      <div class="intro_description" style={{color: 'white'}}>
        <p>Derwish is a decentralized “SocialFi” platform that enables Web3 users to profit from pools by estimating crypto assets under various titles.</p>
      </div>
      <div class="section helper">
        <a class="helper_box" href="#" target="_blank" rel="noreferrer">
          <h5>
		  <FontAwesomeIcon icon={faTicket} />
            Rafffle
          </h5>
          <p>Join rafffle to enjoy various rewards and be a part of DAO.</p>
        </a>
        <a class="helper_box" href="#" target="_blank" rel="noreferrer">
          <h5>
		  <FontAwesomeIcon icon={faPeopleGroup} />
            Rafffle DAO
          </h5>
          <p>Join us and enjoy the community fully.</p>
        </a> 
        <Link className="helper_box" to="https://twitter.com/derwishxyz">
          <h5>
		  <FontAwesomeIcon icon={faTwitter} />
            Twitter
          </h5>
          <p>Keep up to date with the latest developments about Derwish.</p>
        </Link> 
      </div>
      <div class="section-s newversion">
          <h2>DERWISH <br />BUILT TO LEAD COMMUNITY</h2>
          <p >We combine the predictive earnings system with a decentralized finance protocol. In this way, the problems encountered in central platforms are eliminated thanks to the features offered by decentralized blockchain technology. Also, since we have a social economy model, we are building a system where we enable the community, namely our customers, to take part in the development of the platform.</p>
          <a href="https://discord.gg/JZrYmJbG" class="mainnet_btn" target="_blank" rel="noreferrer">Go to Discord</a>
      </div>
      <div class="section helper migration">
        <a class="helper_box" href="#" target="_blank" rel="noreferrer">
          <h5>
            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_5549_106525)">
            <path d="M10.0999 4.90308L0.0605469 14.9425L1.47476 16.3567L11.5148 6.31665L11.5148 14.2168H13.5148L13.5148 2.90308L2.20109 2.90308L2.20109 4.90308L10.0999 4.90308Z" fill="black"/>
            </g>
            <defs>
            <clipPath id="clip0_5549_106525">
            <rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
            </clipPath>
            </defs>
            </svg>
            Join the Rafffle!
          </h5>
          <p>Users who join NFT collections with Rafffle will be eligible to participate in the ecosystem we call Rafffle DAO. Users included in this ecosystem will be able to decide on the question titles (Derwish) by voting at specified times, and will be able to benefit from AirDrop and special prizes.</p>
        </a>
        <a class="helper_box teaser" href="#" target="_blank" rel="noreferrer">
        </a>
      </div>

      <div class="section-s last">
        <h3>Our Roadmap</h3>
        <div class="roadmap_progress"></div>
        <ul class="list">
          <li>
            <h5>Q2 2023</h5>
            <p>First Derwish <br></br>Derwish Protocol Launch<br></br> Rafffle Launch</p>
          </li>
          <li>
            <h5>Q3 2023</h5>
            <p>Partnerships<br></br> Raffle DAO Growth</p>
          </li>
          <li>
            <h5>Q4 2023</h5>
            <p>CEX Listing<br></br> Multi-chain Launch</p>
          </li>
          <li>
            <h5>Q1 2024</h5>
            <p>Derwish v2 Launch</p>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default IndexPage
